<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <b-col
            v-if="isMobile()"
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-space_between mb-1 mb-md-0"
          >
            <div class="bulk_selection d-flex">
              <div class="action-left pt-50">
                <b-form-checkbox
                  :checked="selectAllServiceRequestCheckbox"
                  :indeterminate="isSelectAllServiceRequestCheckboxIndeterminate"
                  @change="selectAllCheckboxUpdate"
                >
                  Select All
                </b-form-checkbox>
              </div>
              <b-dropdown
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="mx-1"
                right
                text="Bulk Actions"
                variant="primary"
                :disabled="!selectedServiceRequests.length"
              >
                <b-dropdown-item
                  v-if="canViewThisAction('update', 'ServiceRequest')"
                  v-b-modal.modal-update-status-all-service-request-bulk
                >
                  Update Status
                </b-dropdown-item>
                <!-- <b-dropdown-item
                  v-if="canViewThisAction('delete', 'ServiceRequest')"
                  @click="deleteBulkServiceRequests"
                >
                  Delete Service Request(s)
                </b-dropdown-item> -->
                <b-dropdown-item
                  v-if="canViewThisAction('update', 'ServiceRequest')"
                  v-b-modal.modal-update-assignee-all-service-request-bulk
                >
                  Assign Selected Request(s)
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="canViewThisAction('read', 'ServiceRequest')"
                  v-b-modal.modal-print-sticker-preview
                >
                  Print Stickers
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <span class="text-muted">Showing <br>{{ dataMeta.from }} - {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>
          <!-- Per Page -->
          <b-col
            v-else
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <div class="action-left pt-50">
              <b-form-checkbox
                :checked="selectAllServiceRequestCheckbox"
                :indeterminate="isSelectAllServiceRequestCheckboxIndeterminate"
                @change="selectAllCheckboxUpdate"
              >
                Select All
              </b-form-checkbox>
            </div>
            <b-dropdown
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mx-1"
              right
              text="Bulk Actions"
              variant="primary"
              :disabled="!selectedServiceRequests.length"
            >
              <!-- <b-dropdown-item @click="downloadBulkCSV">
                Download CSV
              </b-dropdown-item> -->
              <b-dropdown-item
                v-if="canViewThisAction('update', 'ServiceRequest')"
                v-b-modal.modal-update-status-all-service-request-bulk
              >
                Update Status
              </b-dropdown-item>
              <!-- <b-dropdown-item
                v-if="canViewThisAction('delete', 'ServiceRequest')"
                @click="deleteBulkServiceRequests"
              >
                Delete Service Request(s)
              </b-dropdown-item> -->
              <b-dropdown-item
                v-if="canViewThisAction('update', 'ServiceRequest')"
                v-b-modal.modal-update-assignee-all-service-request-bulk
              >
                Assign Selected Request(s)
              </b-dropdown-item>
              <b-dropdown-item
                v-if="canViewThisAction('read', 'ServiceRequest')"
                v-b-modal.modal-print-sticker-preview
              >
                Print Stickers
              </b-dropdown-item>
            </b-dropdown>
            <span class="text-muted">Showing {{ dataMeta.from }} - {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <a
                href="javascript:void(0)"
                class="nav-link nav-link-search"
                @click="showSearchBar = !showSearchBar"
              >
                <feather-icon
                  icon="SearchIcon"
                  size="21"
                  class="mr-50"
                />
                <span class="align-middle">Search</span>
              </a>
              <b-input-group
                v-if="showSearchBar"
                class="input-group-merge mr-50"
              >
                <b-form-input
                  v-model="searchQuery"
                  placeholder="Search..."
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer"
                    @click="searchQuery = ''; showSearchBar = !showSearchBar"
                  />
                </b-input-group-append>
              </b-input-group>

              <b-dropdown
                id="dropdown-form-all-service-request"
                ref="filter_dropdown_all_service_request"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="dropdown-modal"
                right
                variant="link"
              >
                <template #button-content>
                  <feather-icon
                    icon="FilterIcon"
                    size="21"
                    class="align-middle mr-50"
                  />
                  <span class="mr-1">Filters</span>
                </template>
                <service-request-filters
                  :venue-filter.sync="venueFilter"
                  :offering-filter.sync="offeringFilter"
                  :service-date-filter.sync="serviceDateFilter"
                  :venue-options="venueOptions"
                  :request-type.sync="requestTypeFilter"
                  :request-type-options="requestTypeOptions"
                  :assignee.sync="assigneeFilter"
                  :assignee-options="assigneeOptions"
                  :offering-options="kitchenItemsData"
                  @emitHideFilter="hideFilter"
                  @emitShowFilter="showFilter"
                />
              </b-dropdown>
            </div>
          </b-col>
        </b-row>
        <div v-if="serviceDateFilter || requestTypeFilter.length || assigneeFilter.length || venueFilter.length || offeringFilter.length">
          <hr class="dividerHR filter-divider">
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="12"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <ul
                class="list-inline d-inline-block mb-1"
              >
                <li
                  v-if="dueDateFilter"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="dueDateFilter = ''"
                  >
                    {{ dueDateFilter }}
                  </b-form-tag>
                </li>
                <li
                  v-if="serviceDateFilter"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="serviceDateFilter = ''"
                  >
                    {{ serviceDateFilter }}
                  </b-form-tag>
                </li>
                <li
                  v-for="requestTypeVal in requestTypeFilter"
                  :key="requestTypeVal"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="removeFromRequestTypeFilter(requestTypeVal)"
                  >
                    <span class="text-capitalize">{{ resolveRequestTypeName(requestTypeVal) }}</span>
                  </b-form-tag>
                </li>
                <li
                  v-for="assigneeVal in assigneeFilter"
                  :key="assigneeVal"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="removeFromAssigneeFilter(assigneeVal)"
                  >
                    <span class="text-capitalize">{{ resolveAssigneeName(assigneeVal) }}</span>
                  </b-form-tag>
                </li>
                <li
                  v-for="venueVal in venueFilter"
                  :key="venueVal"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="removeFromVenueFilter(venueVal)"
                  >
                    <span class="text-capitalize">{{ venueVal.replace("-", " ") }}</span>
                  </b-form-tag>
                </li>
                <li
                  v-for="offeringVal in offeringFilter"
                  :key="offeringVal"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="removeFromOfferingFilter(offeringVal)"
                  >
                    <span class="text-capitalize">{{ resolveKitchenItemName(offeringVal) }}</span>
                  </b-form-tag>
                </li>
                <li
                  class="list-inline-item"
                >
                  <span
                    class="text-danger clear-filter-text mb-50"
                    @click="clearAllFilters"
                  >
                    Clear Filters
                  </span>
                </li>
              </ul>
            </b-col>
          </b-row>
        </div>
      </div>

      <b-table
        ref="refAllServiceRequestsTable"
        class="position-relative has_padding event_list min-height-200"
        :items="fetchServiceRequests"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        no-sort-reset
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #thead-top>
          <th colspan="4" />
          <th
            :colspan="kitchenItemsLength"
            class="text-center"
            style="background-color: #D2DCEA !important"
          >
            <span>Offerings</span>
          </th>
          <th colspan="4" />
        </template>
        <template #cell(stringID)="data">
          <div
            v-if="data.item._id"
            class="text-nowrap"
          >
            <b-form-checkbox
              :checked="selectedServiceRequests.includes(data.item._id)"
              @change="toggleSelectedServiceRequests(data.item._id)"
              @click.native.stop
            >
              <b-button
                v-if="canViewThisAction('show', 'ServiceRequest')"
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                variant="link"
                class="weight-600 align-middle detail-view-id"
                :to="{ name: 'operation-service-requests-show', params: { id: data.item._id } }"
              >
                {{ data.item.stringID }}
                <br>
                <span class="font_normal">fr.</span>
                <b-button
                  v-if="canViewThisAction('show', 'ServiceForm')"
                  v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                  variant="none"
                  class="text-bold-primary align-middle detail-view-id"
                  :to="{ name: 'operation-service-forms-show', params: { id: data.item.serviceForm } }"
                >
                  {{ data.item.serviceFormStringID }}
                </b-button>
                <b-button
                  v-else
                  v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                  variant="none"
                  class="weight-400 align-middle detail-view-id not-button"
                >
                  {{ data.item.serviceFormStringID }}
                </b-button>
              </b-button>
              <b-button
                v-else
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                variant="none"
                class="text-bold-black align-middle detail-view-id not-button"
              >
                {{ data.item.stringID }}
                <span class="font_normal">fr.</span>
                <b-button
                  v-if="canViewThisAction('show', 'ServiceForm')"
                  v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                  variant="none"
                  class="text-bold-primary align-middle detail-view-id"
                  :to="{ name: 'operation-service-forms-show', params: { id: data.item.serviceForm } }"
                >
                  {{ data.item.serviceFormStringID }}
                </b-button>
                <b-button
                  v-else
                  v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                  variant="none"
                  class="weight-400 align-middle detail-view-id not-button"
                >
                  {{ data.item.serviceFormStringID }}
                </b-button>
              </b-button>
            </b-form-checkbox>
          </div>
          <div
            v-else
            class="text-nowrap text-bold-primary align-middle detail-view-id"
          >
            <span class=""><b>Total Items to be prepared</b></span>
          </div>
        </template>

        <template #cell(date)="data">
          <div class="text-nowrap">
            <span class="">{{ data.item.date ? dateFormat(data.item.date) : '' }}</span>
          </div>
        </template>

        <template #cell(time)="data">
          <div class="text-nowrap">
            <span class="">{{ data.item.date ? timeFormatFromDateString(data.item.date) : '' }}</span>
          </div>
        </template>

        <template #cell(location)="data">
          <div class="text-nowrap">
            <span class="">{{ getLocationFromDescription(data.item.serviceDescription) }}</span>
          </div>
        </template>

        <!-- <template #cell(serviceDescriptionValues)="data">
          <div
            v-if="data.item.showServiceDescription == true"
          >
            <div
              v-for="(opt, key) in data.item.serviceDescriptionValues"
              :key="key"
            >
              <div
                v-if="((Array.isArray(opt.value) && opt.value.length) || opt.value || (opt.children && opt.children.length))"
              >
                <span v-if="opt.children && opt.children.length">
                  <div
                    v-for="(child, indx) in opt.children"
                    :key="indx"
                  >
                    <div
                      v-if="((Array.isArray(child.value) && child.value.length) || child.value)"
                    >
                      <span
                        v-if="Array.isArray(child.value)"
                      >
                        <span
                          v-for="(option, idx) in child.value"
                          :key="idx"
                        >
                          {{ option }}
                          <feather-icon
                            :id="'unique_'+data.item._id+key+idx"
                            icon="AlertCircleIcon"
                            class="ml-50 text-primary"
                            size="14"
                          />
                          <b-tooltip
                            placement="auto"
                            :target="'unique_'+data.item._id+key+idx"
                            :title="child.key"
                          />
                        </span>
                      </span>
                      <span v-else-if="child.value">
                        <span
                          v-if="(child.type && ['increment', 'short-answer', 'long-answer'].includes(child.type))"
                        >
                          {{ child.key }} <span class="green-text">{{ child.value }}</span>
                        </span>
                        <span v-else>{{ child.key }} {{ child.value }}</span>
                      </span>
                    </div>
                  </div>
                </span>
                <span
                  v-if="Array.isArray(opt.value)"
                >
                  <span
                    v-for="(option, idx) in opt.value"
                    :key="idx"
                  >
                    {{ option }}
                    <feather-icon
                      :id="'unique_'+data.item._id+key+idx"
                      icon="AlertCircleIcon"
                      class="ml-50 text-primary"
                      size="14"
                    />
                    <b-tooltip
                      placement="auto"
                      :target="'unique_'+data.item._id+key+idx"
                      :title="opt.key"
                    />
                  </span>
                </span>
                <span v-else-if="opt.value">
                  <span
                    v-if="(opt.type && ['increment', 'short-answer', 'long-answer'].includes(opt.type))"
                  >
                    {{ opt.key }} <span class="green-text">{{ opt.value }}</span>
                  </span>
                  <span v-else>{{ opt.key }} {{ opt.value }}</span>
                </span>
              </div>
            </div>
          </div>
          <div v-else>
            -
          </div>
        </template> -->
        <template
          v-for="flow in kitchenItemsData"
          v-slot:[`cell(${flow.id})`]="data"
        >
          <!-- eslint-disable -->
          <div
            v-if="data.item.serviceDescription"
            class="text-nowrap"
          >
            <span class="">{{ resolveKitchenItems(data.item.serviceDescription, flow.id) }}</span>
          </div>
          <div
            v-else
            class="text-nowrap text-bold-primary align-middle detail-view-id"
          >
            <span class=""><b>{{ resolveKitchenTotalItems(data.item.totals, flow.id) }}</b></span>
          </div>
          <!-- eslint-enable -->
        </template>

        <template #cell(serviceForm)="data">
          <div class="text-nowrap">
            <span class="">{{ data.item.customerName }}</span>
            <br>
            <span
              v-if="data.item.customerContact"
              class=""
            >
              {{ data.item.customerContact }}
            </span>
          </div>
        </template>

        <template #cell(assignees)="data">
          <div
            v-if="!data.item.assignees"
            class="text-nowrap"
          />
          <div
            v-else-if="data.item.assignees.length"
            class="text-nowrap"
          >
            <span
              v-for="(user, key) in data.item.assignees"
              :key="key"
            >
              {{ user.name }}<span v-if="key < (data.item.assignees.length - 1)">, </span>
            </span>
          </div>
          <div v-else>
            -
          </div>
        </template>

        <template #cell(remarks)="data">
          <div class="text-nowrap">
            <span class="">{{ data.item.remarks }}</span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template
          v-if="canViewThisAction('show', 'ServiceRequest') || canViewThisAction('update', 'ServiceRequest')
            || canViewThisAction('update-detail', 'ServiceRequest') || canViewThisAction('update-assignee', 'ServiceRequest')
            || canViewThisAction('update-status', 'ServiceRequest') || canViewThisAction('delete', 'ServiceRequest')"
          #cell(actions)="data"
        >
          <b-dropdown
            v-if="data.item._id"
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <!-- <b-dropdown-item
              v-if="canViewThisAction('update', 'ServiceRequest')"
              @click="assignSingleRequest(data.item._id, data.item.assignees)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Add Assignee</span>
            </b-dropdown-item> -->

            <b-dropdown-item
              v-if="canViewThisAction('show', 'ServiceRequest')"
              :to="{ name: 'operation-service-requests-show', params: { id: data.item._id } }"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">View</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="canViewThisAction('update', 'ServiceRequest')
                || canViewThisAction('update-detail', 'ServiceRequest') || canViewThisAction('update-assignee', 'ServiceRequest')
                || canViewThisAction('update-status', 'ServiceRequest')"
              :to="{ name: 'operation-service-requests-edit', params: { id: data.item._id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <!-- <b-dropdown-item
              v-if="canViewThisAction('delete', 'ServiceRequest')"
              @click="deleteEntity(data.item._id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item> -->
          </b-dropdown>
        </template>

      </b-table>
    </b-card>

    <div
      v-if="totalServiceRequests > perPage && !serviceDateFilter"
      class="mx-2 mb-2"
    >
      <b-row>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center pagination-content"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalServiceRequests"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            align="center"
            prev-text="Prev"
            next-text="Next"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
              <span>Prev</span>
            </template>
            <template #next-text>
              <span>Next</span>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>

    <b-modal
      id="modal-update-status-all-service-request-bulk"
      ref="update-status-modal-all-bulk"
      modal-class="status-update-modal"
      cancel-variant="flat-primary"
      ok-variant="success"
      ok-title="Update"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Update Status"
      @ok="updateBulkStatus"
    >
      <b-form @submit.prevent="updateBulkStatus">
        <validation-observer
          ref="allServiceRequestsBulkStatusEditForm"
        >
          <b-form-group
            label="Status*"
            label-for="h-event-status"
          >
            <validation-provider
              #default="{ errors }"
              name="Status"
              vid="status"
              rules="required"
            >
              <v-select
                id="h-event-status"
                v-model="bulkStatus"
                label="title"
                :options="statusDataOptions"
                :reduce="title => title.code"
                :clearable="false"
                @input="bulkStatusValidation == true ? bulkStatusValidation = false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="bulkStatusValidation"
                class="text-danger"
              >
                {{ bulkStatusError }}
              </small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-update-assignee-all-service-request-bulk"
      ref="update-assignee-modal-all-bulk"
      modal-class="status-update-modal"
      cancel-variant="flat-primary"
      ok-variant="success"
      ok-title="Confirm Changes"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Assign Selected Requests"
      @ok="assignBulkServiceRequests"
    >
      <b-card-text>
        If you wish to assign or reassign the requests, please select their name from the list below:
      </b-card-text>
      <b-form @submit.prevent="assignBulkServiceRequests">
        <validation-observer
          ref="allServiceRequestsBulkAssigneeEditForm"
        >
          <b-form-group
            label=""
            label-for="h-event-assignee"
          >
            <validation-provider
              #default="{ errors }"
              name="Assignee"
              vid="assignee"
              rules=""
            >
              <v-select
                id="h-event-assignee"
                v-model="bulkAssignee"
                label="name"
                multiple
                :options="assigneeOptions"
                :reduce="name => name._id"
                :clearable="true"
                @input="checkForAssigneeSchedule"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="bulkAssigneeValidation"
                class="text-danger"
              >
                {{ bulkAssigneeError }}
              </small>
            </validation-provider>
          </b-form-group>
          <div
            v-if="assignmentError"
            class="service_assign_error"
          >
            <h5>Warning</h5>
            <div>
              <p>Assingee(s) may not be available for the the following Service Requests. Are you sure you want to proceed?</p>
              <ul>
                <li
                  v-for="(option, index) in assignmentErrorOptions"
                  :key="index"
                >
                  {{ option.date }} - {{ option.request }} - {{ option.name }} ({{ option.leave }})
                </li>
              </ul>
            </div>
          </div>
          <b-form-checkbox
            v-model="confirmVal"
            value="accepted"
            unchecked-value=""
            class="custom-control-primary"
          >
            Send message about these assignments to related service Agent(s)
          </b-form-checkbox>
        </validation-observer>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-print-sticker-preview"
      ref="create-new-category-modal"
      modal-class="status-update-modal"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Select Sticker Options"
      @ok="printStickerPreview"
      @cancel="closePrintStickerPreviewModal"
    >
      <b-form @submit.prevent="printStickerPreview">
        <validation-observer
          ref="registerNewItemModalForm"
        >
          <validation-provider
            #default="{ errors }"
            name="Sticker Type"
            vid="sticker-type"
            rules="required"
          >
            <b-form-group
              label="Sticker Type"
              label-for="sticker-type"
              :state="(errors.length > 0 || stickerTypeValidation) ? false : null"
            >
              <v-select
                id="sticker-type"
                v-model="stickerType"
                label="text"
                :options="stickerTypeOptions"
                :reduce="text => text.value"
                :clearable="false"
                placeholder="Select from list"
                @input="stickerTypeValidation == true ? stickerTypeValidation = false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="stickerTypeValidation"
                class="text-danger"
              >
                {{ stickerTypeError }}
              </small>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="{ errors }"
            name="Skip Sticker No"
            vid="skip-sticker-no"
            rules="required"
          >
            <b-form-group
              label-for="skip-sticker-no"
              :state="(errors.length > 0 || skipStickerNoValidation) ? false : null"
            >
              <template #label>
                <span>Number of Stickers to Skip</span>
                <br>
                <span style="font-weight: 400;">Use this to start printing from the middle on a previously used sheet of stickers.</span>
              </template>
              <v-select
                id="skip-sticker-no"
                v-model="skipStickerNo"
                :options="skipStickerNoOptions"
                :clearable="false"
                @input="skipStickerNoValidation == true ? skipStickerNoValidation = false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="skipStickerNoValidation"
                class="text-danger"
              >
                {{ skipStickerNoError }}
              </small>
            </b-form-group>
          </validation-provider>
        </validation-observer>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="ok()"
        >
          <span class="align-middle">Preview</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormInput, BTable, BFormCheckbox, BInputGroup, BInputGroupAppend, BCardText,
  BDropdown, BDropdownItem, BPagination, BForm, BFormGroup, BButton, BFormTag, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { ref, onUnmounted, computed } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils as useAclUtils } from '@/libs/acl/custom'

import store from '@/store'

import useAllServiceRequestList from './useAllServiceRequestList'
import serviceRequestStoreModule from '../serviceRequestStoreModule'
import ServiceRequestFilters from '../ServiceRequestFilters.vue'

export default {
  components: {
    // UserListAddNew,
    BButton,
    BForm,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BFormTag,
    BCardText,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    vSelect,

    ServiceRequestFilters,
    ValidationProvider,
    ValidationObserver,
  },
  directives: { 'b-tooltip': VBTooltip, Ripple },
  props: {
    requestTypeOptions: {
      type: Array,
      required: true,
    },
    assigneeOptions: {
      type: Array,
      required: true,
    },
    venueOptions: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    parentServiceDate: {
      type: [String, null],
      default: '',
    },
  },
  data() {
    return {
      tableColumns: [],
      bulkStatus: 'completed',
      bulkStatusValidation: false,
      bulkStatusError: 'Valid status is required',
      bulkAssignee: [],
      bulkAssigneeValidation: false,
      assignmentError: false,
      alreadyCheckedSchedule: false,
      assignmentErrorOptions: [],
      bulkAssigneeError: 'Valid assignee is required',
      confirmVal: 'accepted',
      stickerType: 82,
      kitchenItemsLength: 0,
      stickerTypeValidation: false,
      stickerTypeError: 'Sticker type is required',
      skipStickerNo: [0],
      skipStickerNoValidation: false,
      skipStickerNoError: 'Skip sticker no is required',
      statusDataOptions: [
        { title: 'Awaiting Approval', code: 'awaiting approval' },
        { title: 'Completed', code: 'completed' },
        { title: 'Pending', code: 'pending' },
      ],
      stickerTypeOptions: [
        { text: '8 pieces on A4 (105 X 74.25mm)', value: 82, size: 'size_2' },
        { text: '8 pieces on A4 (99.1 X 67.7mm)', value: 81, size: 'size_1' },
        { text: '4 pieces on A4 (105 X 148.5mm)', value: 42, size: 'size_2' },
        { text: '4 pieces on A4 (98 X 140mm)', value: 41, size: 'size_1' },
      ],
      serviceRequestItems: [],
      showFilterDropdown: process.env.VUE_APP_SHOW_FILTER_DROPDOWN,

      required,
    }
  },
  computed: {
    skipStickerNoOptions() {
      if (this.stickerType === 82 || this.stickerType === 81) {
        return ['0', '1', '2', '3', '4', '5', '6', '7']
      }
      return ['0', '1', '2', '3']
    },
  },
  watch: {
    parentServiceDate: {
      immediate: true,
      handler(val) {
        if (this.serviceDateFilter !== val) {
          this.serviceDateFilter = val
        }
      },
    },
    serviceDateFilter: {
      immediate: true,
      handler(val) {
        if (this.parentServiceDate !== val) {
          this.$emit('update:parentServiceDate', val)
        }
      },
    },
  },
  mounted() {
    this.$http.get('operation/service-requests/kitchen-view/table-columns')
      .then(response => {
        this.tableColumns = response.data.tableColumns || []
        this.kitchenItemsLength = response.data.kitchenItemsLength || 0
      })
    // window.addEventListener('beforeunload', () => {
    //   localStorage.removeItem('stickerType')
    //   localStorage.removeItem('skipStickerNo')
    //   localStorage.removeItem('serviceRequestItems')
    // })
  },

  methods: {
    hideFilter() {
      this.$refs.filter_dropdown_all_service_request.hide(true)
    },
    showFilter() {
      this.$refs.filter_dropdown_all_service_request.show(true)
    },
    removeFromVenueFilter(item) {
      const index = this.venueFilter.indexOf(item)
      if (index > -1) {
        this.venueFilter.splice(index, 1)
      }
    },
    removeFromOfferingFilter(item) {
      const index = this.offeringFilter.indexOf(item)
      if (index > -1) {
        this.offeringFilter.splice(index, 1)
      }
    },
    removeFromRequestTypeFilter(item) {
      const index = this.requestTypeFilter.indexOf(item)
      if (index > -1) {
        this.requestTypeFilter.splice(index, 1)
      }
    },
    removeFromAssigneeFilter(item) {
      const index = this.assigneeFilter.indexOf(item)
      if (index > -1) {
        this.assigneeFilter.splice(index, 1)
      }
    },
    printStickerPreview() {
      // this.serviceRequestItems = []
      // this.selectedServiceRequests.forEach(item => {
      //   const sRSingleItem = this.serviceRequestData.find(sRData => sRData._id === item)
      //   this.serviceRequestItems.push(sRSingleItem)
      // })
      // if (this.serviceRequestItems.length) {
      //   localStorage.setItem('stickerType', this.stickerType)
      //   localStorage.setItem('skipStickerNo', this.skipStickerNo)
      //   localStorage.setItem('serviceRequestItems', JSON.stringify(this.serviceRequestItems))
      //   this.$router.push('/operations/service-requests/kitchen-view/print/stickers')
      // }
      localStorage.setItem('stickerType', this.stickerType)
      localStorage.setItem('skipStickerNo', this.skipStickerNo)
      localStorage.setItem('serviceRequestItems', JSON.stringify(this.selectedServiceRequests.filter(s => s)))
      if (this.stickerType === 82 || this.stickerType === 81) {
        this.$router.push('/operations/service-requests/kitchen-view/print/stickers')
      } else {
        this.$router.push('/operations/service-requests/kitchen-view/print/four-stickers')
      }
    },
    closePrintStickerPreviewModal() {
      this.$root.$emit('bv::hide::modal', 'modal-print-sticker-preview', '')
    },
    resolveRequestTypeName(id) {
      const request = this.requestTypeOptions.find(o => o._id === id)
      if (request) {
        return request.name || ''
      }
      return ''
    },
    resolveKitchenItemName(id) {
      const item = this.kitchenItemsData.find(o => o.id === id)
      if (item) {
        return item.key || ''
      }
      return ''
    },
    resolveKitchenTotalItems(totals, id) {
      let item = ''
      if (totals) {
        totals.forEach(arrayItem => {
          if (id === arrayItem.key) {
            item = arrayItem.value
          }
        })
        if (item) {
          return item
        }
        return '0'
      }
      return item
    },
    resolveKitchenItems(serviceDescription, id) {
      let counter = 0
      serviceDescription.forEach(arrayItem => {
        if (id === arrayItem.id) {
          counter = 0
          if (arrayItem.type === 'file') {
            if (arrayItem.value) {
              /* eslint-disable-next-line no-plusplus */
              counter++
            }
          } else if (arrayItem.type === 'title-and-text') {
            if (arrayItem.value) {
              /* eslint-disable-next-line no-plusplus */
              counter++
            }
          } else if (arrayItem.type === 'short-answer') {
            if (arrayItem.value) {
              /* eslint-disable-next-line no-plusplus */
              counter++
            }
          } else if (arrayItem.type === 'long-answer') {
            if (arrayItem.value) {
              /* eslint-disable-next-line no-plusplus */
              counter++
            }
          } else if (arrayItem.type === 'multi-select') {
            if (arrayItem.value.length) {
              /* eslint-disable-next-line no-plusplus */
              counter += arrayItem.value.length
            }
          } else if (arrayItem.type === 'single-select') {
            if (arrayItem.value) {
              /* eslint-disable-next-line no-plusplus */
              counter++
            }
          } else if (arrayItem.value) {
            /* eslint-disable-next-line no-plusplus */
            counter += arrayItem.value
          }
        }
      })
      if (counter) {
        return counter
      }

      return '-'
    },
    assignSingleRequest(id, assignees) {
      // const serviceRequestIndex = this.selectedServiceRequests.indexOf(id)

      // if (serviceRequestIndex === -1) this.selectedServiceRequests.push(id)
      this.selectedServiceRequests = []
      this.selectedServiceRequests.push(id)
      this.bulkAssignee = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < assignees.length; i++) {
        this.bulkAssignee.push(assignees[i]._id)
      }
      this.$root.$emit('bv::show::modal', 'modal-update-assignee-all-service-request-bulk', '')
    },
    getLocationFromDescription(data) {
      if (data && data.length) {
        const venue = data.find(o => o.key === this.venueFieldName)
        if (venue && Array.isArray(venue.value) && venue.value.length) {
          return venue.value[0]
        }
      }
      return ''
    },
    checkForAssigneeSchedule() {
      // eslint-disable-next-line no-unused-expressions
      this.bulkAssigneeValidation === true ? this.bulkAssigneeValidation = false : null
      const formData = new FormData()
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.bulkAssignee.length; i++) {
        formData.append('bulkAssignee[]', this.bulkAssignee[i])
      }

      const srs = this.selectedServiceRequests.filter(s => s)
      const srsLength = srs.length
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < srsLength; i++) {
        formData.append('serviceRequests[]', srs[i])
      }
      if (this.bulkAssignee.length) {
        this.$http.post('operation/service-requests/check/assignee/schedule', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
          .then(response => {
            if (response.data.assigneeWarning.length) {
              this.assignmentErrorOptions = response.data.assigneeWarning
              this.assignmentError = true
              // this.$root.$emit('bv::show::modal', 'modal-update-assignee-all-service-request-bulk', '')
            } else {
              this.assignmentErrorOptions = []
              this.assignmentError = false
            }
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
    resolveAssigneeName(id) {
      const user = this.assigneeOptions.find(o => o._id === id)
      if (user) {
        return user.name || ''
      }
      return ''
    },

    clearAllFilters() {
      this.dueDateFilter = ''
      this.serviceDateFilter = ''
      this.venueFilter = []
      this.offeringFilter = []
      this.requestTypeFilter = []
      this.assigneeFilter = []
    },
    updateBulkStatus() {
      this.$refs.allServiceRequestsBulkStatusEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('status', this.bulkStatus)
          if (this.$route.name === 'operation-service-forms-show') {
            formData.append('serviceForm', this.$route.params.id)
          }

          const srs = this.selectedServiceRequests.filter(s => s)
          const srsLength = srs.length
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < srsLength; i++) {
            formData.append('serviceRequests[]', srs[i])
          }

          this.$http.patch('operation/service-requests/action/bulk/status/update', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              if (response.data.formUpdated) {
                this.$emit('emitFetchServiceForm')
              }
              this.refetchData()
              this.$emit('emitCountServiceRequest')
              this.$swal({
                title: 'Status Updated!',
                html: 'The status for selected service requests has been updated',
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'bulkStatus') {
                    this.bulkStatusError = validationError.msg
                    this.bulkStatusValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
    assignBulkServiceRequests(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.allServiceRequestsBulkAssigneeEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('confirmVal', this.confirmVal)
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.bulkAssignee.length; i++) {
            formData.append('assignees[]', this.bulkAssignee[i])
          }

          const srs = this.selectedServiceRequests.filter(s => s)
          const srsLength = srs.length
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < srsLength; i++) {
            formData.append('serviceRequests[]', srs[i])
          }

          this.$http.patch('operation/service-requests/action/bulk/assignee/update', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(() => {
              this.refetchData()
              // this.$emit('emitCountServiceRequest')
              this.alreadyCheckedSchedule = false
              this.assignmentErrorOptions = []
              this.assignmentError = false
              this.$root.$emit('bv::hide::modal', 'modal-update-assignee-all-service-request-bulk', '')
              this.$swal({
                title: 'Assignee Updated!',
                html: 'Assignees for selected service requests has been updated',
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'assignees') {
                    this.bulkAssigneeError = validationError.msg
                    this.bulkAssigneeValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
    deleteBulkServiceRequests() {
      this.$swal({
        title: 'Are you sure?',
        html: 'This will <strong>delete</strong> selected service request(s). This action cannot be undone.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete!',
        cancelButtonText: 'No, Go Back',
        customClass: {
          confirmButton: 'btn btn-danger ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()
            if (this.$route.name === 'operation-service-forms-show') {
              formData.append('serviceForm', this.$route.params.id)
            }

            const srs = this.selectedServiceRequests.filter(s => s)
            const srsLength = srs.length
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < srsLength; i++) {
              formData.append('serviceRequests[]', srs[i])
            }

            this.$http.patch('operation/service-requests/action/bulk/delete', formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(response => {
                if (response.data.formUpdated) {
                  this.$emit('emitFetchServiceForm')
                }
                this.refetchData()
                this.$emit('emitCountServiceRequest')
                this.selectedServiceRequests = []
                // this.$nextTick(() => {
                //   this.$refs['update-status-modal'].toggle('#toggle-btn')
                // })
                this.$swal({
                  title: 'Service Request(s) Deleted!',
                  html: 'Selected service request(s) has been deleted',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    deleteEntity(id) {
      this.$swal({
        title: 'Are You Sure?',
        html: 'Selecting Delete will <strong>permanently delete</strong> this item. This action cannot be undone.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, Delete!',
        customClass: {
          confirmButton: 'btn btn-danger ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.toDeletedID = id
            this.deleteServiceRequest()
          }
        })
    },
  },
  setup() {
    const showSearchBar = ref(false)
    const showFilterOptions = ref(false)
    const SERVICE_REQUEST_APP_STORE_MODULE_NAME = 'app-all-service-requests'

    // Register module
    if (!store.hasModule(SERVICE_REQUEST_APP_STORE_MODULE_NAME)) store.registerModule(SERVICE_REQUEST_APP_STORE_MODULE_NAME, serviceRequestStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SERVICE_REQUEST_APP_STORE_MODULE_NAME)) store.unregisterModule(SERVICE_REQUEST_APP_STORE_MODULE_NAME)
    })

    const statusOptions = [
      { label: 'Assigned', value: 'assigned' },
      { label: 'Unassigned', value: 'unassigned' },
    ]

    const { canViewThisAction } = useAclUtils()

    const {
      fetchServiceRequests,
      perPage,
      currentPage,
      totalServiceRequests,
      serviceRequestData,
      kitchenItemsData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refAllServiceRequestsTable,
      refetchData,
      venueFieldName,

      toDeletedID,
      deleteServiceRequest,

      // UI
      resolveServiceRequestStatusVariant,

      // Extra Filters
      venueFilter,
      offeringFilter,
      categoryFilter,
      dueDateFilter,
      serviceDateFilter,
      requestTypeFilter,
      assigneeFilter,
    } = useAllServiceRequestList()

    const selectedServiceRequests = ref([])
    const toggleSelectedServiceRequests = serviceRequestId => {
      const serviceRequestIndex = selectedServiceRequests.value.indexOf(serviceRequestId)

      if (serviceRequestIndex === -1) selectedServiceRequests.value.push(serviceRequestId)
      else selectedServiceRequests.value.splice(serviceRequestIndex, 1)
    }
    const selectAllServiceRequestCheckbox = computed(() => serviceRequestData.value.length && (serviceRequestData.value.length === selectedServiceRequests.value.length))
    const isSelectAllServiceRequestCheckboxIndeterminate = computed(() => Boolean(selectedServiceRequests.value.length) && serviceRequestData.value.length !== selectedServiceRequests.value.length)
    const selectAllCheckboxUpdate = val => {
      selectedServiceRequests.value = val ? serviceRequestData.value.map(flow => flow._id) : []
    }

    return {

      // Sidebar

      fetchServiceRequests,
      perPage,
      currentPage,
      totalServiceRequests,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refAllServiceRequestsTable,
      refetchData,
      showSearchBar,
      showFilterOptions,
      toDeletedID,
      deleteServiceRequest,
      serviceRequestData,
      kitchenItemsData,
      venueFieldName,

      // Filter
      avatarText,
      canViewThisAction,

      // UI
      resolveServiceRequestStatusVariant,

      statusOptions,

      // Extra Filters
      venueFilter,
      offeringFilter,
      categoryFilter,
      dueDateFilter,
      serviceDateFilter,
      requestTypeFilter,
      assigneeFilter,

      selectAllServiceRequestCheckbox,
      isSelectAllServiceRequestCheckboxIndeterminate,
      selectedServiceRequests,
      toggleSelectedServiceRequests,
      selectAllCheckboxUpdate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
